import React, {useContext} from "react"
import {Observer, observer} from "mobx-react-lite";
import {StoreContext} from "../../context/StoreContext";
import {Venue, VenueFilter} from "../../generated/graphql";
import {
    CBadge,
    CCard,
    CCardBody,
    CCardHeader,
    CCol, CDataTable,
    CDropdown,
    CDropdownMenu,
    CDropdownToggle,
    CLink,
    CRow
} from "@coreui/react";
import {t} from "ttag";
import CIcon from "@coreui/icons-react";
import {validate as isValidUUID} from "uuid";
import {DateAndTime} from "../DateAndTime";
import Pagination from "../Pagination";
import EditVenue from "../EditVenue";
import Error from "../Error";

const getStatusBadgeColor = (isArchived: boolean) => isArchived ? "secondary" : "success";

const fields = [
    {key: "id", _style: {width: "25%"}},
    "customerByCustomerId",
    "country",
    "city",
    "location",
    {key: "timezone", _style: {width: "15%"}},
    {key: "isArchived", _style: {width: "7%"}},
    {key: "createdAtUtc", _style: {width: "5%"}},
    {
        key: "edit",
        label: "",
        _style: {width: "1%"},
        sorter: false,
        filter: false
    }
];

const VenuesView = observer(() => {
    const store = useContext(StoreContext);
    const {venues, editVenue, newVenue} = store;

    const toggleEdit = (venue: Venue) => {
        if (editVenue.venue?.id === venue.id) {
            editVenue.setVenue(undefined);
        } else {
            editVenue.setVenue(venue);
        }
    };

    const saveVenue = (country: string,
                       city: string,
                       location: string,
                       timezone: string,
                       customerId?: string,
                       isArchived?: boolean) => {
        editVenue.save(country, city, location, timezone, !!isArchived);
    };

    const createVenue = (country: string,
                         city: string,
                         location: string,
                         timezone: string,
                         customerId?: string,
                         isArchived?: boolean) => {
        if (customerId) {
            newVenue.create(customerId, country, city, location, timezone);
        }
    };

    const filter = (str: string) => {
        if (!str) {
            venues.setFilter(undefined);
            return;
        }

        const filterObj = {
            or: [
                {
                    country: {
                        likeInsensitive: `%${str}%`
                    }
                },
                {
                    city: {
                        likeInsensitive: `%${str}%`
                    }
                },
                {
                    location: {
                        likeInsensitive: `%${str}%`
                    }
                },
                {
                    customerByCustomerId: {
                        name: {
                            likeInsensitive: `%${str}%`
                        }
                    }
                }
            ]
        } as VenueFilter;

        if (isValidUUID(str)) {
            filterObj?.or?.push({
                id: {
                    equalTo: str
                }
            });
        }

        venues.setFilter(filterObj);
    };

    const refresh = () => {
        venues.fetchVenues();
    };

    return (
        <CRow>
            <CCol>
                <CCard>
                    <CCardHeader>
                        {t`Venues`}

                        <CLink className="ml-1"
                               title={t`Refresh`}
                               onClick={() => refresh()}
                               disabled={venues.loading}>
                            <CIcon name="cil-sync" className="mfe-2" />
                        </CLink>
                    </CCardHeader>

                    <CCardBody>
                        <CDropdown>
                            <CDropdownToggle color="primary">
                                {t`New venue`}
                            </CDropdownToggle>

                            <CDropdownMenu>
                                <div className="px-4 py-3">
                                    <EditVenue onSubmit={createVenue}
                                               onCancel={() => newVenue.reset()}
                                               loading={newVenue.loading}
                                               error={newVenue.error}
                                               isVertical={true}
                                               hideCancel={true}/>
                                </div>
                            </CDropdownMenu>
                        </CDropdown>

                        <CDataTable
                                noItemsView={{noItems: t`No items`, noResults: t`No filtering results`}}
                                items={(venues.venues && venues.venues.length > 0 && venues.venues) || []}
                                loading={venues.loading}
                                fields={fields}
                                border={true}
                                tableFilter={{external: true, label: t`Filter:`, placeholder: t`type id, country, city, location or customer name`}}
                                onTableFilterChange={filter}
                                columnHeaderSlot={{
                                    id: t`Id`,
                                    customerByCustomerId: t`Customer`,
                                    country: t`Country`,
                                    city: t`City`,
                                    location: t`Location`,
                                    timezone: t`Timezone`,
                                    isArchived: t`Status`,
                                    createdAtUtc: t`Registered`
                                }}
                                scopedSlots={{
                                    id: (venue: Venue) => (
                                        <td style={{cursor: "pointer"}}
                                            onClick={() => navigator.clipboard.writeText(venue.id)}
                                            title={t`Copy to clipboard`}>
                                            {venue.id}
                                        </td>
                                    ),
                                    customerByCustomerId: (venue: Venue) => (
                                        <td>
                                            {venue.customerByCustomerId?.name}
                                            {!!venue.customerByCustomerId?.isArchived && <CBadge color="secondary">
                                                {t`archived`}
                                            </CBadge>}
                                        </td>
                                    ),
                                    isArchived: (venue: Venue) => {
                                        const isArchived = venue.isArchived || !!venue.customerByCustomerId?.isArchived;
                                        return (
                                            <td>
                                                <CBadge color={getStatusBadgeColor(isArchived)}>
                                                    {isArchived ? t`archived` : t`active`}
                                                </CBadge>
                                            </td>
                                        );
                                    },
                                    createdAtUtc: (venue: Venue) => (
                                            <td>
                                                <DateAndTime date={new Date(venue.createdAtUtc)}/>
                                            </td>
                                    ),
                                    edit: (venue: Venue) =>
                                            <Observer>{() =>
                                                    <td className="py-2">
                                                        <CLink onClick={() => toggleEdit(venue)} disabled={editVenue.loading}>
                                                            <CIcon name="cil-pencil" className="mfe-2" />
                                                        </CLink>
                                                    </td>
                                            }</Observer>,
                                    details: (venue: Venue) =>
                                            <Observer key={venue.id}>{() => {
                                                return (
                                                        <React.Fragment>
                                                            {editVenue.venue?.id === venue.id && <CCardBody>
                                                                <EditVenue onSubmit={saveVenue}
                                                                           onCancel={() => editVenue.reset()}
                                                                           venue={venue}
                                                                           loading={editVenue.loading}
                                                                           error={editVenue.error}/>
                                                            </CCardBody>}
                                                        </React.Fragment>
                                                );
                                            }}</Observer>
                                }}
                                underTableSlot={venues.error ? <Error>{venues.error}</Error> : <Pagination loading={venues.loading}
                                                            pageInfo={venues.pageInfo}
                                                            onPage={(page) => venues.setPage(page)}/>}/>
                    </CCardBody>
                </CCard>
            </CCol>
        </CRow>
    );
});

export default VenuesView;