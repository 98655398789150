import {action, computed, observable, reaction, runInAction} from "mobx";
import gql from "graphql-tag";
import {RootStore} from "./RootStore";
import {
    Customer,
    LicenseSession,
    LicenseSessionFilter,
    LicenseSessionsOrderBy,
    Maybe,
    Mutation,
    PriceDefinition,
    RoyaltyDefinition,
    UserRoleType,
    Venue
} from "../generated/graphql";
import {apolloClient, DelayMSDefault, PageInfo, PageParam, wrapForApiErrors} from "../api/graphql/ApolloClient";
import {debounceEffect} from "../utils/MobxUtils";
import {toISODate} from "../utils/DateUtils";
import {
    AggregateCustomersType,
    AggregateDateType,
    CompareSumType,
    CompareType,
    getAllLicensesPageInfo,
    getAllLicensesQuery,
    IncludeUnknown,
    IncludeUnpaid,
    LicenseSessionFilter as LicenseSessionAPIFilter,
    licenseSessionsApi,
    OrderByType
} from "../api/LicenseSessionsApi";
import {t} from "ttag";
import {ExecutionResult} from "graphql";
import {useDotDecimalSeparator} from "../utils/I18nUtils";

class LicenseSessionsStore {
    @observable.ref
    public page: PageParam = {
        after: null,
        before: null,
        isLast: false
    };

    @observable.ref
    public licenseSessions: Maybe<LicenseSession>[] | undefined = undefined;

    @observable
    public showMoreInfoLicenseSessionIds: Set<string> = new Set<string>();

    @observable
    public showMoreOriginalLicenseSessionIds: Set<string> = new Set<string>();

    @observable
    public showMoreApprovedLicenseSessionIds: Set<string> = new Set<string>();

    @observable
    public showMoreSubmittedLicenseSessionIds: Set<string> = new Set<string>();

    @observable
    public showSubmitExportToExcelTaskResult: boolean = false;

    @observable.ref
    public editingLicenseSession: LicenseSession | undefined = undefined;

    @observable
    public editingLicenseSessionStatus: Map<string, {loading: boolean, error: string | undefined}>
        = new Map<string, {loading: boolean, error: string | undefined}>();

    @observable.ref
    public pageInfo: PageInfo | undefined = undefined;

    @observable
    public loading: boolean = false;

    @observable
    public error: string | undefined = undefined;

    private readonly rootStore: RootStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;

        reaction(() => {
            return {
                filter: this.filter,
                orderBy: this.orderBy
            };
        }, () => {
            if (this.pageInfo?.firstPage) {
                this.setPage(this.pageInfo.firstPage);
            }
        });

        reaction(() => {
            return {
                filter: this.filter,
                orderBy: this.orderBy,
                page: this.page
            };
        }, debounceEffect(() => {
            this.fetchLicenseSessions();
        }, DelayMSDefault));
    }

    @computed
    public get filter(): LicenseSessionFilter | undefined {
        return LicenseSessionsStore.constructLicenseSessionFilter(
            this.rootStore.reportsFilter.compareType,
            this.rootStore.reportsFilter.filterIncludeUnpaid,
            this.rootStore.reportsFilter.filterIncludeUnknown,
            this.rootStore.reportsFilter.filterCustomers,
            this.rootStore.reportsFilter.filterCustomerTags,
            this.rootStore.reportsFilter.filterVenues,
            this.rootStore.reportsFilter.filterDateStart,
            this.rootStore.reportsFilter.filterDateEnd
        );
    }

    @computed
    public get orderBy(): LicenseSessionsOrderBy[] {
        switch (this.rootStore.reportsFilter.orderBy) {
            case OrderByType.ByDateAsc:
                return [LicenseSessionsOrderBy.StartTimeLocalAsc, LicenseSessionsOrderBy.CreatedAtUtcAsc];
            case OrderByType.SubmittedFirst:
                return [LicenseSessionsOrderBy.IsApprovedAsc, LicenseSessionsOrderBy.StartTimeLocalDesc, LicenseSessionsOrderBy.CreatedAtUtcDesc];
            case OrderByType.ByDateDesc:
            default:
                return [LicenseSessionsOrderBy.StartTimeLocalDesc, LicenseSessionsOrderBy.CreatedAtUtcDesc];
        }
    }

    @action
    public async fetchLicenseSessions() {
        this.loading = true;
        this.error = undefined;

        try {
            const result = await this.licenseSessionsQuery();

            runInAction(() => {
                this.licenseSessions = result.data.allLicenseSessions?.nodes;
                this.pageInfo = getAllLicensesPageInfo(result.data.allLicenseSessions);
            });
        } catch (error) {
            runInAction(() => {
                this.error = error.getMessage();
            });
        } finally {
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    @action
    private async licenseSessionsQuery() {
        return wrapForApiErrors(getAllLicensesQuery(
            this.orderBy,
            this.page,
            this.filter
        ));
    }

    @action
    public async redetectLicenseSession(licenseSessionId: string) {
        this.loading = true;
        this.error = undefined;

        try {
            await wrapForApiErrors(apolloClient.mutate<Mutation, {
                licenseSessionId: string
            }>({
                mutation: gql`mutation ($licenseSessionId: UUID!) {
                    redetectLicense(input: {licenseSessionId: $licenseSessionId}) {
                        clientMutationId
                    }
                }`,
                variables: {
                    licenseSessionId
                }
            }));

            this.fetchLicenseSessions();
        } catch (error) {
            runInAction(() => {
                this.error = error.getMessage();
            });
        } finally {
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    @action
    public async redetectAllUnknown() {
        this.loading = true;
        this.error = undefined;

        try {
            await licenseSessionsApi.redetectAllUnknown();
        } catch (error) {
            runInAction(() => {
                this.error = error.getMessage();
            });
        } finally {
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    @action
    public async submitExportToExcelTask() {
        this.loading = true;
        this.error = undefined;
        this.showSubmitExportToExcelTaskResult = false;

        try {
            const filter: LicenseSessionAPIFilter = {
                includeUnpaid: this.rootStore.reportsFilter.filterIncludeUnpaid,
                includeUnknown: this.rootStore.reportsFilter.filterIncludeUnknown,
                customerIds: this.rootStore.reportsFilter.filterCustomers.map(customer => customer.id),
                customerTags: this.rootStore.reportsFilter.filterCustomerTags,
                venueIds: this.rootStore.reportsFilter.filterVenues.map(venue => venue.id),
                dateStart: this.rootStore.reportsFilter.filterDateStart ? toISODate(this.rootStore.reportsFilter.filterDateStart) : null,
                dateEnd: this.rootStore.reportsFilter.filterDateEnd ? toISODate(this.rootStore.reportsFilter.filterDateEnd) : null,
                aggregateDateType: this.rootStore.reportsFilter.aggregateDateType,
                aggregateCustomersType: this.rootStore.reportsFilter.aggregateCustomersType,
                aggregateProductsType: this.rootStore.reportsFilter.aggregateProductsType,
                compareType: this.rootStore.reportsFilter.compareType,
                compareSumType: this.rootStore.reportsFilter.compareSumType,
                orderBy: this.rootStore.reportsFilter.orderBy
            };

            const name = this.getReportExportName();

            await licenseSessionsApi.submitExportTask("excel", name, filter, useDotDecimalSeparator(this.rootStore.auth.currentUser?.locale));
        } catch (error) {
            runInAction(() => {
                this.error = error.getMessage();
            });
        } finally {
            runInAction(() => {
                this.loading = false;
                this.showSubmitExportToExcelTaskResult = true;
            });
        }
    }

    @action
    public setPage(page: PageParam) {
        this.page = page;
        this.showMoreInfoLicenseSessionIds.clear();
    }

    @action
    public toggleShowMoreInfo(licenseSessionId: string) {
        if (this.showMoreInfoLicenseSessionIds.has(licenseSessionId)) {
            this.showMoreInfoLicenseSessionIds.delete(licenseSessionId);
        } else {
            this.showMoreInfoLicenseSessionIds.add(licenseSessionId)
        }
    }

    @action
    public toggleShowMoreOriginal(licenseSessionId: string) {
        if (this.showMoreOriginalLicenseSessionIds.has(licenseSessionId)) {
            this.showMoreOriginalLicenseSessionIds.delete(licenseSessionId);
        } else {
            this.showMoreOriginalLicenseSessionIds.add(licenseSessionId)
        }
    }

    @action
    public toggleShowMoreApproved(licenseSessionId: string) {
        if (this.showMoreApprovedLicenseSessionIds.has(licenseSessionId)) {
            this.showMoreApprovedLicenseSessionIds.delete(licenseSessionId);
        } else {
            this.showMoreApprovedLicenseSessionIds.add(licenseSessionId)
        }
    }

    @action
    public toggleShowMoreSubmitted(licenseSessionId: string) {
        if (this.showMoreSubmittedLicenseSessionIds.has(licenseSessionId)) {
            this.showMoreSubmittedLicenseSessionIds.delete(licenseSessionId);
        } else {
            this.showMoreSubmittedLicenseSessionIds.add(licenseSessionId)
        }
    }

    @action
    public resetShowSubmitExportToExcelTaskResult() {
        this.showSubmitExportToExcelTaskResult = false;
    }

    @action
    public reset() {
        this.page = {
            after: null,
            before: null,
            isLast: false
        };

        this.licenseSessions = undefined;
        this.showMoreInfoLicenseSessionIds.clear();
        this.showMoreOriginalLicenseSessionIds.clear();
        this.showMoreApprovedLicenseSessionIds.clear();
        this.showMoreSubmittedLicenseSessionIds.clear();
        this.showSubmitExportToExcelTaskResult = false;
        this.editingLicenseSession = undefined;
        this.editingLicenseSessionStatus.clear();
        this.pageInfo = undefined;
        this.loading = false;
        this.error = undefined;
    }

    private getReportExportName(): string {
        let includeUnpaid: string = "";
        switch (this.rootStore.reportsFilter.filterIncludeUnpaid) {
            case IncludeUnpaid.Include:
                includeUnpaid = t`Include unpaid`;
                break;
            case IncludeUnpaid.NotInclude:
                includeUnpaid = t`Not include unpaid`;
                break;
        }

        let includeUnknown: string = "";
        switch (this.rootStore.reportsFilter.filterIncludeUnknown) {
            case IncludeUnknown.Include:
                includeUnknown = t`, Include unknown`;
                break;
            case IncludeUnknown.NotInclude:
                includeUnknown = t`, Not include unknown`;
                break;
            case IncludeUnknown.IncludeOnly:
                includeUnknown = t`, Include only unknown`;
                break;
        }

        const customers = this.rootStore.reportsFilter.filterCustomers.length > 0 && this.rootStore.auth.currentUser?.role === UserRoleType.VerControlpanelAdmin ?
            (t`, Customers: ` + "[" + this.rootStore.reportsFilter.filterCustomers.map(customer => customer.name).join(", ") + "]") : "";

        const customerTags = this.rootStore.reportsFilter.filterCustomerTags.length > 0 ?
            (t`, Customer tags: ` + "[" + this.rootStore.reportsFilter.filterCustomerTags.join(", ") + "]") : "";

        const venues = this.rootStore.reportsFilter.filterVenues.length > 0 ?
            (t`, Venues: ` + "[" + this.rootStore.reportsFilter.filterVenues.map(venue => `${venue.country} ${venue.city} ${venue.location}`).join(", ") + "]") : "";

        const dateStart = this.rootStore.reportsFilter.filterDateStart ?
            (t`, Start date: ` + toISODate(this.rootStore.reportsFilter.filterDateStart)) : "";

        const dateEnd = this.rootStore.reportsFilter.filterDateEnd ?
            (t`, End date: ` + toISODate(this.rootStore.reportsFilter.filterDateEnd)) : "";

        let aggregateDateType: string = "";
        switch (this.rootStore.reportsFilter.aggregateDateType) {
            case AggregateDateType.ByDay:
                aggregateDateType = t`Aggregate by day`;
                break;
            case AggregateDateType.ByMonth:
                aggregateDateType = t`Aggregate by month`;
                break;
            case AggregateDateType.ByYear:
                aggregateDateType = t`Aggregate by year`;
                break;
        }

        let aggregateCustomersType: string = "";
        switch (this.rootStore.reportsFilter.aggregateCustomersType) {
            case AggregateCustomersType.Split:
                aggregateCustomersType = t`Split by customer`;
                break;
            case AggregateCustomersType.NotSplit:
                aggregateCustomersType = t`Don't split by customer`;
                break;
            case AggregateCustomersType.SplitByVenue:
                aggregateCustomersType = t`Split by venue`;
                break;
        }

        let compareType: string = "";
        switch (this.rootStore.reportsFilter.compareType) {
            case CompareType.Approved:
                compareType = t`Compare approved`;
                break;
            case CompareType.Original:
                compareType = t`Compare original`;
                break;
            case CompareType.Submitted:
                compareType = t`Compare submitted`;
                break;
        }

        let compareSumType: string = "";
        switch (this.rootStore.reportsFilter.compareSumType) {
            case CompareSumType.Total:
                compareSumType = t`Compare total`;
                break;
            case CompareSumType.Max:
                compareSumType = t`Compare max`;
                break;
            case CompareSumType.Min:
                compareSumType = t`Compare min`;
                break;
        }

        return t`License Sessions report: ${includeUnpaid}${includeUnknown}${customers}${customerTags}${venues}${dateStart}${dateEnd}, ${aggregateDateType}, ${aggregateCustomersType}, ${compareType}, ${compareSumType}`;
    }

    @action
    public async saveLicenseSession(
        licenseSessionId: string,
        price: PriceDefinition,
        isPaid: boolean,
        comment: string,
        royalty?: RoyaltyDefinition,
        isLocked?: boolean
    ) {
        this.editingLicenseSessionStatus.set(licenseSessionId, {
            loading: true,
            error: undefined
        });

        try {
            if (this.rootStore.auth.currentUser?.role === UserRoleType.VerControlpanelAdmin && royalty) {
                await this.saveApproveLicenseSessionQuery(licenseSessionId, price, isPaid, royalty, !!isLocked);
            } else {
                await this.submitLicenseSessionQuery(licenseSessionId, price, isPaid);
            }

            if (comment) {
                await this.addLicenseSessionComment(licenseSessionId, this.rootStore.auth.currentUser?.id, comment);
            }

            this.rootStore.licenseSessionsAgg.fetchAgg();
            this.fetchLicenseSessions();

            runInAction(() => {
                this.editingLicenseSession = undefined;
                this.editingLicenseSessionStatus.set(licenseSessionId, {
                    loading: false,
                    error: undefined
                });
            });
        } catch (error) {
            runInAction(() => {
                this.editingLicenseSessionStatus.set(licenseSessionId, {
                    loading: false,
                    error: error.getMessage()
                });
            });
        }
    }

    @action
    private async saveApproveLicenseSessionQuery(
        licenseSessionId: string,
        price: PriceDefinition,
        isPaid: boolean,
        royalty: RoyaltyDefinition,
        isLocked: boolean): Promise<ExecutionResult<Mutation>>
    {
        return wrapForApiErrors(apolloClient.mutate<Mutation, {
            licenseSessionId: string,
            price: PriceDefinition,
            isPaid: boolean,
            royalty: RoyaltyDefinition,
            isLocked: boolean
        }>({
            mutation: gql`mutation ($licenseSessionId: UUID!, $price: PriceDefinitionInput!, $isPaid: Boolean!, $royalty: RoyaltyDefinitionInput!, $isLocked: Boolean!) {
                updateLicenseSessionById(input: {licenseSessionPatch: {price: $price, isPaid: $isPaid, royalty: $royalty, priceApproved: $price, isPaidApproved: $isPaid, isLocked: $isLocked}, id: $licenseSessionId}) {
                    clientMutationId
                }
            }`,
            variables: {
                licenseSessionId,
                price,
                isPaid,
                royalty,
                isLocked
            }
        }));
    }

    @action
    private async submitLicenseSessionQuery(
        licenseSessionId: string,
        price: PriceDefinition,
        isPaid: boolean): Promise<ExecutionResult<Mutation>>
    {
        return wrapForApiErrors(apolloClient.mutate<Mutation, {
            licenseSessionId: string,
            price: PriceDefinition,
            isPaid: boolean
        }>({
            mutation: gql`mutation ($licenseSessionId: UUID!, $price: PriceDefinitionInput!, $isPaid: Boolean!) {
                updateLicenseSessionById(input: {licenseSessionPatch: {price: $price, isPaid: $isPaid}, id: $licenseSessionId}) {
                    clientMutationId
                }
            }`,
            variables: {
                licenseSessionId,
                price,
                isPaid
            }
        }));
    }

    @action
    private async addLicenseSessionComment(licenseSessionId: string, userId: string, comment: string): Promise<ExecutionResult<Mutation>> {
        return wrapForApiErrors(apolloClient.mutate<Mutation, {
            licenseSessionId: string,
            userId: string,
            comment: string
        }>({
            mutation: gql`mutation ($licenseSessionId: UUID!, $userId: UUID!, $comment: String!) {
                createLicenseSessionComment(input: {licenseSessionComment: {licenseSessionId: $licenseSessionId, userId: $userId, comment: $comment}}) {
                    clientMutationId
                }
            }`,
            variables: {
                licenseSessionId,
                userId,
                comment
            }
        }));
    }

    @action
    public editLicenseSession(licenseSession: LicenseSession | undefined) {
        const licIdToReset = licenseSession?.licenseId || this.editingLicenseSession?.licenseId;

        if (licIdToReset) {
            this.editingLicenseSessionStatus.set(licIdToReset, {
                loading: false,
                error: undefined
            });
        }

        setTimeout(() => runInAction(() => {
            this.editingLicenseSession = licenseSession;
        }));
    }

    private static constructLicenseSessionFilter(
        filterCompareBy: CompareType,
        filterIncludeUnpaid: IncludeUnpaid,
        filterIncludeUnknown: IncludeUnknown,
        filterCustomers: Customer[],
        filterCustomerTags: string[],
        filterVenues: Venue[],
        filterDateStart: Date | null,
        filterDateEnd: Date | null
    ): LicenseSessionFilter | undefined {
        const conditions: LicenseSessionFilter[] = [];

        const licenseUnknownCondition = {
            and: [
                {
                    licenseId: {
                        isNull: true
                    }
                },
                {
                    licenseV2Id: {
                        isNull: true
                    }
                }
            ]
        };

        if (filterCustomers.length > 0 && filterIncludeUnknown !== IncludeUnknown.IncludeOnly) {
            const customersCondition = {
                customerId: {
                    in: filterCustomers.map(customer => customer.id)
                }
            };

            if (filterIncludeUnknown === IncludeUnknown.Include) {
                conditions.push({
                    or: [
                        licenseUnknownCondition,
                        customersCondition
                    ]
                });
            } else {
                conditions.push(customersCondition);
            }
        }

        if (filterCustomers.length <= 0 && filterIncludeUnknown === IncludeUnknown.NotInclude) {
            conditions.push({
                not: licenseUnknownCondition
            });
        }

        if (filterCustomerTags.length > 0 && filterIncludeUnknown !== IncludeUnknown.IncludeOnly) {
            const tagsCondition = {
                customerByCustomerId: {
                    tags: {
                        contains: filterCustomerTags
                    }
                }
            };

            if (filterIncludeUnknown === IncludeUnknown.Include) {
                conditions.push({
                    or: [
                        licenseUnknownCondition,
                        tagsCondition
                    ]
                });
            } else {
                conditions.push(tagsCondition);
            }
        }

        if (filterIncludeUnknown === IncludeUnknown.IncludeOnly) {
            conditions.push(licenseUnknownCondition);
        }

        if (filterIncludeUnpaid === IncludeUnpaid.NotInclude) {
            switch (filterCompareBy) {
                case CompareType.Approved:
                    conditions.push({
                        isPaidApproved: {
                            equalTo: true
                        }
                    });
                    break;
                case CompareType.Original:
                    conditions.push({
                        isPaidOriginal: {
                            equalTo: true
                        }
                    });
                    break;
                case CompareType.Submitted:
                    conditions.push({
                        isPaid: {
                            equalTo: true
                        }
                    });
                    break;
            }
        }

        if (filterVenues.length > 0 && filterIncludeUnknown !== IncludeUnknown.IncludeOnly) {
            const venuesCondition = {
                venueId: {
                    in: filterVenues.map(venue => venue.id)
                }
            };

            if (filterIncludeUnknown === IncludeUnknown.Include) {
                conditions.push({
                    or: [
                        licenseUnknownCondition,
                        venuesCondition
                    ]
                });
            } else {
                conditions.push(venuesCondition);
            }
        }

        if (filterDateStart) {
            conditions.push({
                startTimeLocal: {
                    greaterThanOrEqualTo: toISODate(filterDateStart)
                }
            });
        }

        if (filterDateEnd) {
            conditions.push({
                startTimeLocal: {
                    lessThanOrEqualTo: `${toISODate(filterDateEnd)}T23:59:59`
                }
            });
        }

        conditions.push({
            masterSessionId: {
                isNull: true
            }
        });

        return conditions.length <= 0 ? undefined : {
            and: conditions
        };
    }
}

export default LicenseSessionsStore;