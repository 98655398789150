import {StatusCodes} from "http-status-codes";
import {AbstractApi, wrapForApiErrors} from "./AbstractApi";
import {apolloClient, PageInfo, PageParam, PageSizeDefault} from "./graphql/ApolloClient";
import {
    LicenseSessionsConnection,
    LicenseSessionsOrderBy,
    Maybe,
    Query,
    LicenseSessionFilter as LicenseSessionFilterDB
} from "../generated/graphql";
import gql from "graphql-tag";
import MasterLicenseSessionFragment from "./graphql/MasterLicenseSessionFragment";

enum AggregateDateType {
    ByDay = "ByDay",
    ByMonth = "ByMonth",
    ByYear = "ByYear"
}

enum AggregateCustomersType {
    Split = "Split",
    NotSplit = "NotSplit",
    SplitByVenue = "SplitByVenue"
}

enum AggregateProductsType {
    Split = "Split",
    NotSplit = "NotSplit"
}

enum IncludeUnknown {
    NotInclude = "NotInclude",
    Include = "Include",
    IncludeOnly = "IncludeOnly"
}

enum IncludeUnpaid {
    NotInclude = "NotInclude",
    Include = "Include"
}

enum CompareType {
    Submitted = "Submitted",
    Approved = "Approved",
    Original = "Original"
}

enum CompareSumType {
    Total = "Total",
    Min = "Min",
    Max = "Max"
}

enum OrderByType {
    SubmittedFirst = "SubmittedFirst",
    ByDateAsc = "ByDateAsc",
    ByDateDesc = "ByDateDesc"
}

interface LicenseSessionFilter {
    includeUnknown: IncludeUnknown;
    includeUnpaid: IncludeUnpaid;
    customerIds: string[];
    customerTags: string[];
    venueIds: string[];
    dateStart: string | null;
    dateEnd: string | null;
    aggregateDateType: AggregateDateType;
    aggregateCustomersType: AggregateCustomersType;
    aggregateProductsType: AggregateProductsType;
    compareType: CompareType;
    compareSumType: CompareSumType;
    orderBy: OrderByType;
}

class LicenseSessionsApi extends AbstractApi {
    protected baseUrl(): string {
        return `${super.baseUrl()}/api/v1/license_sessions`;
    }

    public submitExportTask(to: "excel", name: string, filter: LicenseSessionFilter, useDotDecimalSeparator: boolean): Promise<Response> {
        return wrapForApiErrors(fetch(`${this.baseUrl()}/export?to=${encodeURIComponent(to)}&name=${encodeURIComponent(name)}&useDotDecimalSeparator=${encodeURIComponent(useDotDecimalSeparator)}`, {
            method: "POST",
            body: JSON.stringify(filter),
            headers: {
                "Content-Type": "application/json"
            },
            credentials: process.env.NODE_ENV === "development" ? "include" : undefined
        }), [StatusCodes.OK]);
    }

    public redetectAllUnknown(): Promise<Response> {
        return wrapForApiErrors(fetch(`${this.baseUrl()}/redetect_all_unknown`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            credentials: process.env.NODE_ENV === "development" ? "include" : undefined
        }), [StatusCodes.OK]);
    }
}

const licenseSessionsApi = new LicenseSessionsApi();

function getAllLicensesPageInfo(allLicenseSessions?: Maybe<LicenseSessionsConnection>): PageInfo {
    return {
        firstPage: !allLicenseSessions?.pageInfo.hasPreviousPage ? undefined : {
            after: null,
            before: null
        },
        lastPage: !allLicenseSessions?.pageInfo.hasNextPage ? undefined : {
            after: null,
            before: null,
            isLast: true
        },
        nextPage: !allLicenseSessions?.pageInfo.hasNextPage ? undefined : {
            after: allLicenseSessions?.pageInfo.endCursor as string,
            before: null
        },
        prevPage: !allLicenseSessions?.pageInfo.hasPreviousPage ? undefined : {
            after: null,
            before: allLicenseSessions?.pageInfo.startCursor as string
        },
        totalCount: allLicenseSessions?.totalCount || 0
    };
}

function getAllLicensesQuery(orderBy: LicenseSessionsOrderBy[],
                             page: PageParam,
                             filter?: LicenseSessionFilterDB) {
    return apolloClient.query<Query, {
        orderByInput: LicenseSessionsOrderBy[],
        filterInput: LicenseSessionFilterDB | undefined,
        afterInput: string | null,
        firstInput: number | null,
        beforeInput: string | null,
        lastInput: number | null
    }>({
        query: gql`query ($orderByInput: [LicenseSessionsOrderBy!], $filterInput: LicenseSessionFilter, $afterInput: Cursor, $firstInput: Int, $beforeInput: Cursor, $lastInput: Int) {
            currentUserId,

            allLicenseSessions(orderBy: $orderByInput, filter: $filterInput, after: $afterInput, first: $firstInput, before: $beforeInput, last: $lastInput) {
                nodes {
                    ...MasterLicenseSessionFragment
                },
                pageInfo {
                    endCursor
                    hasNextPage
                    hasPreviousPage
                    startCursor
                }
                totalCount
            }
        },
        ${MasterLicenseSessionFragment}`,
        variables: {
            orderByInput: orderBy,
            filterInput: filter,
            afterInput: page.after,
            firstInput: page.after || (!page.before && !page.isLast) ? PageSizeDefault : null,
            beforeInput: page.before,
            lastInput: page.before || page.isLast ? PageSizeDefault : null
        }
    });
}

export type {
    LicenseSessionFilter
}

export {
    licenseSessionsApi,
    AggregateDateType,
    AggregateCustomersType,
    AggregateProductsType,
    IncludeUnknown,
    IncludeUnpaid,
    CompareType,
    CompareSumType,
    OrderByType,
    getAllLicensesPageInfo,
    getAllLicensesQuery
};